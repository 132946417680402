import React from 'react';
import Button, { ButtonSize, ButtonStyle } from './button';

interface Props {
  icon: string;
  title: string;
  joinOurCommunitySectionSize: JoinOurCommunitySectionSize;
  buttonLabel: string;
  handleOnClick: () => void;
  desc?: string;
}

export enum JoinOurCommunitySectionSize {
  L,
  S,
}

const JoinOurCommunitySection: React.FC<Props> = ({
  icon,
  title,
  desc,
  joinOurCommunitySectionSize,
  buttonLabel,
  handleOnClick,
}) => {
  return (
    <>
      <div className="wrap">
        <div className="icon"></div>
        <p className="title LG h7-medium">{title}</p>
        {desc && <p className="desc G200 b2-regular">{desc}</p>}
        <Button
          label={buttonLabel}
          buttonStyle={ButtonStyle.LINEAR}
          buttonSize={ButtonSize.S}
          handleClick={() => {
            handleOnClick();
          }}
          _hoverStyle={'background: rgba(7, 94, 83, 1)!important;'}
        />
      </div>
      <style jsx>{`
        div.wrap {
          background: var(--G900);
          border-radius: 28px;
          width: ${
            joinOurCommunitySectionSize === JoinOurCommunitySectionSize.L
              ? 'calc(490px - 56px);'
              : 'calc(321px - 56px);'
          }
          padding: 28px;
          text-align: center;
        }

        div.icon {
          margin: 0 auto;
          background: url(${icon}) center center no-repeat;
          background-size: ${
            joinOurCommunitySectionSize === JoinOurCommunitySectionSize.L
              ? '60px 60px;'
              : '42px 42px;'
          }
          width: ${
            joinOurCommunitySectionSize === JoinOurCommunitySectionSize.L
              ? '60px;'
              : '42px;'
          }
          height: ${
            joinOurCommunitySectionSize === JoinOurCommunitySectionSize.L
              ? '60px;'
              : '42px;'
          }
        }
        p {
        
        }
        .title {
          margin-bottom: 8px;
        }
        .desc {
          margin-top: -6px;
          margin-bottom: 8px;
        }
      `}</style>
    </>
  );
};

export default JoinOurCommunitySection;
