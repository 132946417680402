import { useRouter } from 'next/router';
import React from 'react';
import { ROUTE } from 'src/apis/const';
import { NotableBadgesAPI } from 'src/apis/notable-badges-api';
import { UsersAPI } from 'src/apis/users-api';
import { Badge as BadgeComponent } from 'src/components';
import { Badge } from 'src/entity/badge';
import { User } from 'src/entity/user';
import { Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

import { Swiper, SwiperSlide } from 'swiper/react';
import Loading from './loading';

const BadgeSlide: React.FC<{ user: User | undefined }> = ({ user }) => {
  const router = useRouter();
  const api = user ? new UsersAPI() : new NotableBadgesAPI();
  const { data: badges } = api.useGetNotableBadges();

  if (!badges) {
    return (
      <>
        <div className="loading">
          <Loading />
        </div>
        <style jsx>
          {`
            .loading {
              width: 100%;
              height: 445px;
            }
          `}
        </style>
      </>
    );
  }

  // const _badge = {
  //   _id: '629f13433f871bf6b7f7ef3c',
  //   id: '15444889299172373665829439727327145032790141370669428948429080615428596695360',
  //   name: 'X2Y2 Airdrop Recipient',
  //   totalClaimed: 4,
  //   nftName: 'X2Y2 Airdrop Recipient',
  //   categories: ['NFT'],
  //   descriptionEligibility: 'Received X2Y2 airdrop 1+ times',
  //   imageUrl:
  //     'https://res.noox.world/bafybeic6gnypm6huno27vpixhqlsylue3524vtjvodtvmjnhbx36yrisfy',
  //   imageThumbnailUrl:
  //     'https://res.noox.world/bafkreiel2qt4vaijpcsspiervlypwl5wnlaetkpwzaok2glyy5gnkefap4',
  //   revision: 1,
  //   isLatestRevision: true,
  //   isClaimed: true,
  //   collections: [
  //     {
  //       id: '62eb7e948ea63f07695c61d9',
  //       name: 'Uniswap test',
  //       slugName: 'uniswap-test',
  //     },
  //   ],
  //   group: {
  //     groupId: '629f13403f871bf6b7f7ed88',
  //     index: 6,
  //     count: 11,
  //     isTiered: false,
  //   },
  //   isVisible: true,
  //   claimedDate: '2022-08-04T08:30:06.000Z',
  //   claimedRevision: 1,
  //   _idx: 6,
  // };

  // const _badges = [
  //   {
  //     ...(_badge as any),
  //     id: '15444889299172373665829439727327145032790141370669428948429080615428596695273',
  //   },
  //   {
  //     ...(_badge as any),
  //     id: '15444889299172373665829439727327145032790141370669428948429080615428596695274',
  //   },
  //   {
  //     ...(_badge as any),
  //     id: '15444889299172373665829439727327145032790141370669428948429080615428596695275',
  //   },
  //   {
  //     ...(_badge as any),
  //     id: '15444889299172373665829439727327145032790141370669428948429080615428596695276',
  //   },
  //   {
  //     ...(_badge as any),
  //     id: '15444889299172373665829439727327145032790141370669428948429080615428596695277',
  //   },
  //   {
  //     ...(_badge as any),
  //     id: '15444889299172373665829439727327145032790141370669428948429080615428596695278',
  //   },
  //   {
  //     ...(_badge as any),
  //     id: '15444889299172373665829439727327145032790141370669428948429080615428596695279',
  //   },
  //   {
  //     ...(_badge as any),
  //     id: '15444889299172373665829439727327145032790141370669428948429080615428596695280',
  //   },
  // ];

  const _chunkBadges = badges
    .map((badge) => badge.badge)
    .reduce((resultArray: any[], item, index) => {
      const chunkIndex = Math.floor(index / 4);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

  return (
    <>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="swiper"
        style={{
          width: '1228px',
        }}
        speed={500}
        loop={true}
      >
        {_chunkBadges.map((chunk: Badge[], i: number) => {
          return (
            <>
              <SwiperSlide className="slide" key={i}>
                <div
                  className="wrap"
                  style={{
                    display: 'flex',
                    gap: 20,
                    justifyContent: 'center',
                  }}
                >
                  {chunk.map((badge: Badge) => (
                    <BadgeComponent
                      key={badge.id}
                      badge={badge}
                      style={{
                        boxShadow: 'none',
                      }}
                      handleClick={() => {
                        router.push(`${ROUTE.BADGE}/${badge.id}`);
                      }}
                    />
                  ))}
                </div>
              </SwiperSlide>
            </>
          );
        })}
      </Swiper>
      <style jsx>{`
        .swiper {
          width: 100%;
          height: 445px;
        }
      `}</style>
    </>
  );
};

export default BadgeSlide;
