import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { DISCORD_URL, ROUTE } from 'src/apis/const';
import { Button, DefaultHeadDom } from 'src/components';
import BadgeSlide from 'src/components/common/badge-slide';
import { ButtonSize, ButtonStyle } from 'src/components/common/button';
import Icon from 'src/components/common/icon';
import JoinOurCommunitySection, {
  JoinOurCommunitySectionSize,
} from 'src/components/common/join-our-community-section';
import useConnectWallet from 'src/hooks/use-connect-wallet';
import useUser from 'src/hooks/use-user';
import { codePointsToString } from 'src/utils';
import { SWRConfig } from 'swr';
import { SWR } from '../apis/swr';

interface HomeProps {
  fallback: Record<string, any>;
}

const Home: React.FC = () => {
  const [profile, profileLoading] = useUser();
  const [renderConnectWalletModals] = useConnectWallet();
  const router = useRouter();

  return (
    <>
      <DefaultHeadDom />
      <div>
        {renderConnectWalletModals()}
        <main>
          <section id="section1">
            <div className="container-1440">
              <div className="left">
                <h2>
                  Proof of Achievements
                  <br />
                  <span>in Your Soul</span>
                </h2>
                <p className="main-text h6-regular">
                  Collect achievement badges as Soulbound NFTs <br />
                  and build your on-chain reputation
                </p>
                <div className="button-list">
                  <div>
                    <Button
                      label={'Explore Badges'}
                      buttonStyle={ButtonStyle.PRIMARY}
                      buttonSize={ButtonSize.L}
                      handleClick={() => {
                        router.push(`${ROUTE.BADGE}`);
                      }}
                    />
                  </div>
                  {/* <div>
                    <Button
                      isDisabled={true}
                      label="Create Badge"
                      buttonStyle={ButtonStyle.SECONDARY}
                      buttonSize={ButtonSize.L}
                      handleClick={() => {
                        router.push(`${ROUTE.USER_BADGE}`);
                      }}
                      style={{
                        padding: '16px 35px',
                      }}
                    />
                  </div> */}
                </div>
              </div>
              <div className="right">
                <div className="anim">
                  <iframe
                    style={{
                      border: 'none',
                    }}
                    width="480"
                    height="700"
                    src="https://rive.app/s/eMSL1ikXykOpOBavRzmimg/embed"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
          <section id="section2">
            <div className="container-1440">
              <h3 className="h4-bold">Notable Achievements</h3>
              <iframe
                style={{
                  border: 'none',
                }}
                width="400"
                height="52"
                src="https://rive.app/s/1DugWKJ-t0uhf2Y5FaXdHA/embed"
                allowFullScreen
              ></iframe>
              {!(!profile && profileLoading) && <BadgeSlide user={profile} />}
            </div>
          </section>
          <section id="section3">
            <div className="img1 container-1440">
              <div className="text-box">
                <h3 className="h3-bold">
                  Prove what you did and
                  <br />
                  unlock new achievements
                </h3>
                <ul>
                  <li>
                    <span className="icon h7-medium">
                      <Icon iconKey="check" />
                    </span>
                    <span>
                      <span className="LG h7-bold">
                        Discover notable achievements&nbsp;
                      </span>
                      around Web3 curated by the community
                    </span>
                  </li>
                  <li>
                    <span className="icon h7-medium">
                      <Icon iconKey="check" />
                    </span>
                    <span>
                      <span className="LG h7-bold">
                        Check your eligibility&nbsp;
                      </span>
                      and collect achievements as NFTs
                    </span>
                  </li>
                  <li>
                    <span className="icon h7-medium">
                      <Icon iconKey="check" />
                    </span>
                    <span>
                      <span className="LG h7-bold">
                        Bound your achievements to your soul
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex">
              <div className="img2"></div>
              <div className="text-box">
                <h3 className="h3-bold">
                  Create your own <br />
                  on-chain achievments
                </h3>
                <ul>
                  <li>
                    <span className="icon h7-medium">
                      <Icon iconKey="check" />
                    </span>
                    <span>
                      <span className="LG h7-bold">
                        No need to create a whitelist.&nbsp;
                      </span>
                      Eligibility checking based on live data
                    </span>
                  </li>
                  <li>
                    <span className="icon h7-medium">
                      <Icon iconKey="check" />
                    </span>
                    <span>
                      <span className="LG h7-bold ">
                        No coding required.&nbsp;
                      </span>
                      Just input smart contract info and a few parameters.
                    </span>
                  </li>
                  <li>
                    <span className="icon h7-medium">
                      <Icon iconKey="check" />
                    </span>
                    <span>
                      <span className="LG h7-bold ">
                        Template-based design,&nbsp;
                      </span>
                      but offers customizability
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="backed-by">
              <div className="container-1440">
                <h3 className="h5-bold">Backed by</h3>
                <div className="bg"></div>
              </div>
            </div>
          </section>
          <section id="section4">
            <div className="container-1440">
              <h3 className="h5-bold">Join our community</h3>
              <div className="wrap">
                <JoinOurCommunitySection
                  icon="/image/partnership-icon.png"
                  title="Partnership"
                  desc={`Any collaboration or integration requests? LFG ${codePointsToString(
                    ['U+1F680'],
                  )}`}
                  buttonLabel="Apply now"
                  joinOurCommunitySectionSize={JoinOurCommunitySectionSize.L}
                  handleOnClick={() => {
                    window.open('mailto:hello@noox.world', '_blank')?.focus();
                  }}
                />
                <JoinOurCommunitySection
                  icon="/image/feedback-icon.png"
                  title="Forum / Feedback"
                  desc={`Please tell us about your experience with Noox.`}
                  buttonLabel="Give feedback"
                  joinOurCommunitySectionSize={JoinOurCommunitySectionSize.L}
                  handleOnClick={() => {
                    window
                      .open('https://discuss.noox.world/', '_blank')
                      ?.focus();
                  }}
                />
                <JoinOurCommunitySection
                  icon="/image/twitter-icon.png"
                  title="Twitter"
                  buttonLabel="Follow on Twitter"
                  joinOurCommunitySectionSize={JoinOurCommunitySectionSize.S}
                  handleOnClick={() => {
                    window
                      .open('https://twitter.com/noox_world', '_blank')
                      ?.focus();
                  }}
                />
                <JoinOurCommunitySection
                  icon="/image/discord-icon-new.png"
                  title="Discord"
                  buttonLabel="Join our Discord"
                  joinOurCommunitySectionSize={JoinOurCommunitySectionSize.S}
                  handleOnClick={() => {
                    window.open(DISCORD_URL, '_blank')?.focus();
                  }}
                />
                <JoinOurCommunitySection
                  icon="/image/mirror-icon.png"
                  title="Mirror"
                  buttonLabel="Read our Mirror"
                  joinOurCommunitySectionSize={JoinOurCommunitySectionSize.S}
                  handleOnClick={() => {
                    window
                      .open('https://mirror.xyz/noox.eth', '_blank')
                      ?.focus();
                  }}
                />
              </div>
            </div>
          </section>
        </main>
        <style global jsx>
          {`
            html,
            body {
              background: #0a0e2e !important;
            }
          `}
        </style>
        <style jsx>
          {`
       
            main {
              width: 100%;
              margin: 0 auto;
              min-width: 1440px;
             
              width: 100%;
              min-height: 700px;
              margin-top: -120px;
            }
            .container-1440 {
              width: 1440px;
              min-width: 1000px;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              align-items: center
              padding-top: 100px;
              justify-content: center;
            }
            h3 {
              text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
            }
            .bg {
              width: 100%;
            }
            .desc {

            }
            #section1 {
              background: var(--G900);
              padding-top: 80px;
            }
            #section1 .container-1440 {
              align-items: center;
              min-height: 700px;
              flex-direction: row;
            }
            #section1 .container-1440 .left h2 {
              font-size: 6.4rem;
              line-height: 8.0rem;
              text-align: left;
              font-weight: 700;
              width: 760px;
              margin-bottom: 28px;
            }
            #section1 .container-1440 .left h2 span {
              background: linear-gradient(103.23deg, #D080FF 14.45%, #8C98FF 73.81%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            #section1 .container-1440 .left p.main-text {
              margin-bottom: 28px;
            }
            #section1 .container-1440 .left .button-list {
              display: flex;
              gap: 20px;
            }
            #section1 .container-1440 .right  {
              width: 480px;
              min-height: 700px;
            }

            
            #section2 {
              width: 100%;
              background: var(--G900);
              padding-top: 100px;
              padding-bottom: 100px;
            }
            #section2 .continer-1440 {
              
            }
            #section2 .continer-1440 h2 {
              margin-bottom: 40px;
            }
            #section2 h3 {
              margin: 0 auto;
              margin-bottom: 40px;
            }
            #section2 iframe {
              margin: 0 auto 40px;
            }

            #section3 {
              background: url(/image/section3-bg.png) center center no-repeat;
              background-size: 1440px 1600px;
              width: 100%;
            }
             #section3 > div {
              width: 1200px;
            }
            #section3 .img1 {
              width: 1200px;
              padding: 0px 120px;
              background: url(/image/section3-img1.png) bottom center no-repeat;
              height: 708px;
              background-size: 1440px 708px;
            }

            #section3 > .flex {
              width: 1200px;
              margin: 0 auto;
              padding: 52px 0px 66.83px;
              display: flex;
              gap: 60px;
            }
            #section3 > .flex .img2 {
              background: url(/image/section3-img2.png) center center no-repeat;
              min-width: 490px;
              height: 602.17px;
              background-size: 490px 602.17px;
            }
            #section3 .text-box {
              max-width: 660px;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            #section3 .text-box h3 {
              margin-bottom: 24px;
            }

            #section3 ul li {
              font-size: 2rem;
              line-height: 2.8rem;
              display: flex;
              margin-bottom: 8px;
            }

            #section3 ul li span.icon {
              display: inline-block;
              min-width: 24px;
              height: 28px;
              color: var(--LG);
              margin-right: 6px;
            }

            #section3 .backed-by {
              background: var(--WT10);
              padding: 40px 0px 52.33px;
              width: 100%;

            }
            #section3 .backed-by h3 {
              margin: 0 auto;
              margin-bottom: 20px;
            }
            #section3 .backed-by .bg {
              background: url(/image/logo-bg.png) center center no-repeat;
              background-size: 1100px 62.33px;
              width: 1100px;
              height: 62.33px;
              margin: 0 auto;
              
            }

            #section4 {
              width: 100%;
              background: var(--G800);
              padding-top: 60px;
              padding-bottom: 60px;
            }
            #section4 .container-1440 {
              
            }

            #section4 h3 {
              margin: 0 auto 20px;
            }

            #section4 .wrap {
              display: flex;
              width: 1200px;
              margin: 0 auto;
              flex-wrap: wrap;
              gap: 16px;
              justify-content: center;
            }

            // @media (max-width: 1440px) {
            //   main {
            //     min-width: 0px;
            //     width: 100%;
            //   }
            //   .container-1440 {
            //     width: 100%;
            //   }
            // }

            @media (min-width: 1440px) {
              main {
                width: 100%;
              }
            
              #section3 .container-1440 {
         
              }
              #section3 {
                background: url(/image/section3-bg.png) center center no-repeat;
                background-size: 100% auto;
              }
             
            }

           
          `}
        </style>
      </div>
    </>
  );
};

const HomePage: NextPage<HomeProps> = () => {
  return (
    <SWRConfig value={{ ...(SWR.GLOBAL_SETTING as any) }}>
      <Home />
    </SWRConfig>
  );
};

export default HomePage;
